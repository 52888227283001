import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { InventoryClient } from "../../../clients/InventoryClient"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import { Button, ButtonLink } from "../../atoms/Button"
import Icon from "../../atoms/Icon"
import { Link } from "../../atoms/Link"
import { DealersClient } from "../../../clients/DealersClient"
import { LocationContext } from "../../../contexts/Location"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { openContactDealerModal } from "../../../contexts/Contact/actions"
import { ContactContext } from "../../../contexts/Contact"
import DealerSocialNetworks from "./SocialNetworks"
import { LanguageContext } from "../../../contexts/Language"
import { generateInventoryLink } from "../../../helpers"

const DealerContact: React.FC = () => {
  const { dealer } = useContext(DealerDetailsPageContext)
  const [{ zip, radius }] = useContext(LocationContext)
  const [_contactState, contactDispatch] = useContext(ContactContext)
  const [dealerInventory, setDealerInventory] = useState<number>(0)
  const [dealerDistance, setDealerDistance] = useState<number | "...">(0)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { _ } = useContext(LanguageContext)

  function formatPhoneNumber(phoneNumber: string) {
    const cleaned = phoneNumber?.replace(/\D/g, "")
    const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`
    }
    return phoneNumber
  }

  useEffect(() => {
    if (!zip) return
    setDealerDistance("...")
    const getDealerDistance = async () => {
      // TODO this code should actually call the getByDealerCode endpoint -- but it doesn't currently return just the one dealer
      // so for now, continue getting a bunch of dealers and just filter out the one we want
      const dealers = await DealersClient.getForLocation(
        zip,
        radius,
        5,
        dealer?.DealerCode,
      )
      const theDealer = dealers?.filter(
        d => d.DealerCode === dealer?.DealerCode,
      )
      if (theDealer && theDealer.length > 0) {
        setDealerDistance(theDealer?.[0].Distance)
      }
    }
    getDealerDistance()
  }, [zip])

  useEffect(() => {
    try {
      const setInventoryCount = async () => {
        const { count } = await InventoryClient.get({
          dealer: dealer?.DealerCode,
          countOnly: true,
        })
        setDealerInventory(count)
      }
      setInventoryCount()
    } catch (e) {
      console.error("Error getting inventory count", e)
      setDealerInventory(0)
    }
  }, [])

  // Parameters:  Name of event and an object with additional properties for tealium event call if required
  const handleTealEvent: (eventName: string, moreData?: {}) => void = (
    eventName = "No event name provided",
    moreData = {},
  ) => {
    trackTealEvent({
      tealium_event: eventName,
      dealer_name: dealer?.Name,
      dealer_code: dealer?.DealerCode,
      ...moreData,
    })
  }

  const inventoryLinkOptions = {
    dealer: dealer?.DealerCode,
  }

  const inventoryCountText = dealerInventory > 0 ? ` (${dealerInventory})` : ""

  /**
   *  Windows users were seeing blank page when clicking on phone number link.
   *  If detected user is on a Windows device, we will display the phone number as text.
   */
  const isWindows =
    typeof window !== "undefined" ? navigator.userAgent.includes("Win") : false

  return (
    <div
      css={[
        tw`col-start-1 col-span-12 row-start-1`,
        tw`md:(col-start-8 col-span-5)`,
      ]}
    >
      <div
        css={[
          tw`grid overflow-hidden grid-cols-12 grid-rows-1 gap-1 grid-flow-row w-auto h-auto mx-0 mt-4`,
          tw`md:(mx-4)`,
        ]}
      >
        <div css={tw`col-start-1 col-end-7`}>
          <div css={[tw`grid grid-cols-12 mt-3 gap-x-4 md:(gap-x-2)`]}>
            <div css={[tw`col-span-2 text-right`]}>
              <Icon.Directions
                color="red-400"
                css={[tw`max-h-8 w-8 mt-1 inline-block`]}
              />
            </div>
            <div css={[tw`col-span-10 ml-1`]}>
              <span css={[tw`block text-sm font-semibold`]}>
                {typeof dealerDistance === "number" && dealerDistance > 0 && (
                  <span>
                    {dealerDistance} {_("mi away")}{" "}
                  </span>
                )}
              </span>
              <Link
                to={`https://www.google.com/maps/search/?api=1&query=${dealer?.Name} ${dealer?.Address1} ${dealer?.City}, ${dealer?.State} ${dealer?.Zip}`}
                css={[tw`text-red-500 p-0 my-1 text-sm`]}
                animated
                animatedThin
                onClick={() => handleTealEvent("get_directions")}
                analytics-id={"directions:dealer info:"}
              >
                {dealer?.Address1}
                <br />
                {dealer?.City}, {dealer?.State} {dealer?.Zip}
              </Link>
            </div>
            <div css={[tw`col-span-2 text-right`]}>
              <Icon.Phone
                color="red-400"
                css={[tw`max-h-6 w-6 mt-1 inline-block`]}
              />
            </div>
            <div css={[tw`col-span-10 text-red-500 text-sm ml-1`]}>
              {isWindows ? (
                <div css={tw`my-2 p-0`}>{formatPhoneNumber(dealer?.Phone)}</div>
              ) : (
                <Link
                  to={`tel:${dealer?.Phone}`}
                  css={[tw`my-2 p-0`]}
                  animated
                  animatedThin
                  onClick={() => handleTealEvent("click_to_call")}
                  analytics-id="call:dealer info:"
                >
                  {formatPhoneNumber(dealer?.Phone)}
                </Link>
              )}
            </div>
          </div>
        </div>
        <div
          css={[
            tw`col-start-7 text-xs text-red-400 col-span-6 text-right`,
            tw`md:()`,
          ]}
        >
          <div css={[tw`text-xs grid grid-cols-12 mt-3 gap-x-4 md:(gap-x-2)`]}>
            <div css={[tw`col-span-2 text-right`]}>
              <Icon.Globe
                color="red-400"
                css={[tw`max-h-6 w-7 my-1 inline-block`]}
              />
            </div>
            <div css={[tw`col-span-10 text-red-500 ml-1 text-left`]}>
              <Link
                to={`${dealer?.MainDealerUrl}`}
                target="_blank"
                css={[tw`text-sm p-0 my-1`]}
                animated
                animatedThin
                onClick={() => handleTealEvent("visit_dealer_website")}
                analytics-id="visit website:dealer info:"
              >
                {_("Visit Website")}
              </Link>
            </div>
            <div css={[tw`col-span-2 text-right`]}>
              <Icon.Wrench
                color="red-400"
                css={[tw`max-h-6 w-7 mt-2 inline-block`]}
              />
            </div>
            <div css={[tw`col-span-10 text-red-500 ml-1 text-left`]}>
              <Link
                to={dealer?.ServiceUrl}
                target="_blank"
                css={[tw`text-sm p-0 my-1`]}
                animated
                animatedThin
                onClick={() => handleTealEvent("schedule_service")}
                analytics-id="schedule service:dealer info:"
              >
                {_("Schedule Service")}
              </Link>
            </div>
          </div>
        </div>
        <div css={[tw`col-start-1 col-span-12 flex my-6`, tw`md:(my-0)`]}>
          <Button
            primary
            css={[tw`text-xs px-4 m-2`]}
            onClick={() => {
              handleTealEvent("contact_dealer_rest")
              contactDispatch(openContactDealerModal(dealer))
            }}
            analytics-id="contact:dealer info:"
          >
            {_("Contact Dealer")}
          </Button>
          <ButtonLink
            secondary
            css={[tw`text-xs px-4 m-2 cursor-pointer`]}
            to={generateInventoryLink(inventoryLinkOptions)}
            onClick={() => {
              return trackTealEvent({
                dealer_name: dealer?.Name,
                tealium_event: "view_inventory_click",
                dealer_code: dealer?.DealerCode,
                link_href: generateInventoryLink(inventoryLinkOptions),
                coupon_module_text: "View Inventory",
              })
            }}
            analytics-id="inventory:dealer info:"
            alt={""}
          >
            {_("View Inventory") + inventoryCountText}
          </ButtonLink>
        </div>
        <div css={[tw`col-start-1 col-span-12 flex mb-6`]}>
          <DealerSocialNetworks />
        </div>
      </div>
    </div>
  )
}

export default DealerContact
